import * as React from "react"
// import Heading from "../components/heading/Heading"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"
import Services from "../components/services/Services"
import WhyUs3 from "../components/whyUs/WhyUs3"
import Cta from "../components/cta/Cta"
import Services2 from "../components/services/Services2"
import Cta2 from "../components/cta/Cta2"
import Cta3 from "../components/cta/Cta3"
import Localization from "../components/localization/Localization"
import Cta4 from "../components/cta/Cta4"
import Cta5 from "../components/cta/Cta5"
import Cta6 from "../components/cta/Cta6"
import { Helmet } from "react-helmet"
import { isBrowser } from "../ultis/functions"
import Branches from "../components/branches/branches"

const CookiesPage = () => (
  <>
    <Helmet>
      {isBrowser() ? (
        <script>
          {`(()=>{
            const url_string = window.location.href
            const url = new URL(url_string)
            const urlOrgin = url.origin
            const urlSearchParams = url.searchParams; 
            const params = Object.fromEntries(urlSearchParams.entries());
            const allLinks = document.querySelectorAll('a');
            let paramsToUrl = '?';

            Object.entries(params).forEach(([key, value])=>{
              paramsToUrl += key + '=' + value + '&';
            });

            if(allLinks.length){
              allLinks.forEach((item)=>{
                const itemHref = item.getAttribute('href');
                item.href = itemHref+paramsToUrl;
              })
            }

        })()
        `}
        </script>
      ) : (
        ""
      )}
    </Helmet>
    <Layout className="offerPage">
      <Seo
        title="Pakiety medyczne dla firm i abonamenty medyczne dla rodzin"
        description="Szukasz szybkiego i łatwego dostępu do najlepszych lekarzy i badań? Sprawdź pakiety medyczne dla firm oraz abonamenty medyczne dla rodzin ENEL-MED!"
      />
      <div className="container container--small">
        {/* <Heading type="2" size="XL" className="offerPage__subHeading"  text="Kliknij „Dowiedz się więcej” i sprawdź co dla Ciebie przygotowaliśmy." /> */}
        <Services />
        <Cta6 />
        <WhyUs3 />
        <Cta />
        {/* <Services2 /> */}
        <Cta2 />
        <Branches />
        {/* <Cta3 /> */}
        {/* <Localization /> */}
        <Cta4 />
        <Cta5 />
      </div>
    </Layout>
  </>
)

export default CookiesPage
